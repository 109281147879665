h1, h2, h3, h4, h5, h6, body, form {
  margin: 0;
}

body {
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font: 100% / 1.5 Google Sans Text, Google Sans, Roboto, sans-serif;
}

/*# sourceMappingURL=index.d5d75d0c.css.map */
